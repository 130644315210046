
import { defineComponent, ref, watchEffect, Ref, onMounted } from 'vue';
import { get7day } from '@/api/api'
import * as echarts from "echarts";

export default defineComponent({
  name: 'RbsQty7Day',
  setup() {
    const myChart = ref<HTMLElement>();  //也可以用const myChart = ref<any>();
    const myCharts = ref<any>();

    const InfoData = ref([]);

    // const names = ref([]);
    // const datas = ref([]);
    const namesData = [];
    const valueData = [];




    onMounted(() => {

      get7day({ type: '0' }).then((res: any) => {


        if (res.code == 0) {


          InfoData.value = res.result.rbs7dayNumber

          res.result.rbs7dayNumber.forEach((item) => {
            valueData.push(item.value);
            namesData.push(item.date);

          })


          chartInit()


        }

      })

    });

    const chartInit = () => {

      myCharts.value = echarts.init(myChart.value!);
      myCharts.value.setOption({
        // title: { text: "总用户量" },
        title: { show: false },
        tooltip: {},
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: namesData,
        },
        yAxis: {
          minInterval: 1,
          splitLine: {
            // lineStyle: {
            //   //     type: 'dashed',  // 分隔线类型为虚线
            //   //color: '#1e334d'     // 分隔线颜色为灰色
            // },
            interval: 5          // 设置分隔线的间隔距离为 2
          }
        },
        color: ['#19be6b', '#2d8cf0', '#ff9900'],
        grid: {
          top: 20,
          left: 30,
          right: 5,
          bottom: 20
        },
        series: [
          {
            name: "垃圾投放量",
            type: "line",
            smooth: true,
            data: valueData,
            lineStyle: {
              normal: {
                width: 5,
              },
              borderColor: "rgba(255,255,255,.8)",
            },
          },
        ],
      });

    }








    return {
      myChart,
      InfoData
    };

  }
});
