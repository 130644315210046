
import { defineComponent, ref, watchEffect, Ref, onMounted, computed } from 'vue';
import * as echarts from "echarts";
import { getDateData } from '@/api/api'



export default defineComponent({
  name: 'MonthData',
  setup() {
    const myChart = ref<HTMLElement>();  //也可以用const myChart = ref<any>();

    const myCharts = ref<any>();
    const infoData = ref<any>();
    const title = ref<any>();

    onMounted(() => {
      getDateData({ type: '0' }).then((res: any) => {

        if (res.code == 0) {
          infoData.value = res.result.monthSummaryVo

          infoData.value.totalWeight = convertToKilograms(infoData.value.totalWeight)


          infoData.value.data.map((item, index) => {

            // console.log(88888)

            item.value = convertToKilograms(item.value)

            // console.log(item)
            // console.log(888)

            
             return item

          });





          chartInit()
        }
      })
    });
    const chartInit = () => {
      myCharts.value = echarts.init(myChart.value!);
      myCharts.value.setOption({
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: 0
        },

        title: {
          text: infoData.value.totalWeight+"KG",
          subtext: infoData.value.monthStr,
          left: '13%',
          top: '34%',
          textStyle: {
            fontSize: 20,
            color: '#ffffff',
            align: 'center'
          },
          subtextStyle: {
            fontFamily: "微软雅黑",
            fontSize: 14,
            color: '#ffffff',
          }
        },


        // graphic: {
        //   type: 'text',
        //   top: '45%',
        //   left: '18%',
        //   style: {
        //     text: '125.2',
        //     textAlign: 'center',
        //     // textColor:'#fff',
        //     fontSize: 20,
        //     fontWeight: 'bold',
        //     textStyle: {
        //       color: '#fff' // 设置文本颜色为白色
        //     }
        //   }
        // },

        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'middle',
          itemGap: 20, // 设置图例项之间的间隔
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          data: ['易腐垃圾', '可回收物']
        },
        color: ['#00a2ff', '#00c7d9'],
        series: [
          {
            name: 'Month',
            type: 'pie',
            radius: ['80%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'outside',
              // position: 'outside',
              formatter: '{b}: {c} ({d}%)'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            center: ['25%', '50%'],
            data: infoData.value.data
          }
        ]
      });
    }


    const convertToKilograms = (grams: number): number => {
      const kilograms = grams / 1000; // 1 kilogram = 1000 grams
      const roundedKilograms = Math.round(kilograms); // Round to the nearest integer
      return roundedKilograms;
    };







    // setTimeout(() => {
    //   // 绘制图表

    // }, 10);

    return {
      myChart,
      title,
      convertToKilograms
    };

  }
});
