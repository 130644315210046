import request from "@/utils/request";
 
export function getQty7Day(params: any) {
    return request({
        url: '/bigdata/rbsQty7Day',
        method: 'get',
        params
    })
}

/**
 * 
 * @param params 当日数据
 * @returns 
 */
export function getDateData(params: any) {
    return request({
        url: '/api/bigdata/dataIndex',
        method: 'get',
        params
    })
}


export function getEquips(params: any) {
    return request({
        url: '/api/bigdata/getEquips',
        method: 'get',
        params
    })
}

/**
 * 日期数据
 * 
 */
export function getToDayData(params: any) {
    return request({
        url: '/api/bigdata/toDayData',
        method: 'get',
        params
    })
}

/**
 * 院系排名数据
 * @param params 
 * @returns 
 */

export function getDeptRanks(params: any) {
    return request({
        url: '/api/bigdata/getRanks',
        method: 'get',
        params
    })
}

/**
 * 近7天数据
 * @param params 
 * @returns 
 */

export function get7day(params: any) {
    return request({
        url: '/api/bigdata/get7day',
        method: 'get',
        params
    })
}

/**
 * 用户排名数据 
 * getUserWeightRanks
 */

export function getUserWeightRanks(params: any) {
    return request({
        url: '/api/bigdata/getUserWeightRanks',
        method: 'get',
        params
    })
}


/**
 * 日期数据
 * 
 */
export function getVideoToken(params: any) {
    return request({
        url: '/frontend/yingshi/token',
        method: 'get',
        params
    })
}