
import { Options, Vue } from 'vue-class-component';// @ is an alias to /src
import RbsQty7Day from '@/components/RbsQty7Day.vue';
import RbsPassRate7Day from '@/components/RbsPassRate7Day.vue';
import MonthData from '@/components/MonthData.vue';
import ToDayData from '@/components/ToDayData.vue';    
import MonthDeptRank from '@/components/MonthDeptRank.vue';
import YearData from '@/components/YearData.vue';

import DeptChatData from '@/components/DeptData.vue';
import VisualMap from '@/components/VisualMap.vue';
import VideoPlay from '@/components/VideoPlay.vue';
import TipContainer from '@/components/TipContainer.vue';
import UserRank from "@/components/UserRank.vue"
import MiddleLengend from "@/components/MiddleLengend.vue"
import MonthDepartment from '@/components/MonthDepartment.vue';
@Options({
	components: {
		TipContainer,
		RbsQty7Day,
		RbsPassRate7Day,
		MonthData,
		ToDayData,
		DeptChatData,
		VideoPlay,
		YearData,
		MonthDeptRank,
		VisualMap,
		UserRank,
		MiddleLengend,
		MonthDepartment
	},
	data() {
		return {
			ezvizPlay: null
		};
	},
})
export default class HomeView extends Vue {


}
