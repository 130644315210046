
import { defineComponent, ref, watchEffect, Ref, onMounted, computed } from 'vue';
import * as echarts from "echarts";
import { getDateData } from '@/api/api'
import { getDeptRanks } from '@/api/api'
import { collapseItemProps } from 'element-plus';
import { result } from 'lodash';

export default defineComponent({
    name: 'MonthDepartment',
    setup() {


        const showRecyclable = ref<boolean>(true); // 默认显示可回收内容
        const dataList = ref<any>();







        onMounted(() => {

            getDeptRanks({ type: '0' }).then((res: any) => {


                if (res.code == 0) {

                    let depts = res.result.monthDeptRanks


                    depts.forEach((item) => {

                        item.weight = convertToKilograms(item.weight)
                        return item;
                    })

                    dataList.value = depts

                }

            })

        });

        const convertToKilograms = (grams: number): number => {
            const kilograms = grams / 1000; // 1 kilogram = 1000 grams
            const roundedKilograms = Math.round(kilograms); // Round to the nearest integer
            return roundedKilograms;
        };




        return { dataList };

    }
});
