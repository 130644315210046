
import { defineComponent, ref, watchEffect, Ref, onMounted } from 'vue';
import * as echarts from "echarts";
import { getVideoToken } from '@/api/api'
import EZUIKit from 'ezuikit-js';

export default defineComponent({
  name: 'VideoPlay',
  setup() {
    const myChart = ref<HTMLElement>();  //也可以用const myChart = ref<any>();
    const player = ref({}); // 定义响应式数据
    const ezvizPlay = ref({});


    onMounted(() => {


      getVideoToken({ type: '0' }).then((res: any) => {




        if (res.code == 200) {



          try {
            // console.log("start player")
            player.value = new EZUIKit.EZUIKitPlayer({
              id: 'video-container', // 视频容器ID
              accessToken: res.result,
              url: 'ezopen://open.ys7.com/K77932690/1.hd.live',
              audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
              autoplay: true,
              // width: 330,
              height: 250
            })
          } catch (e) {
            console.log(e)
          }


        }


      })





      // ezvizPlay.value = new EZUIKit.EZUIKitPlayer({
      // autoplay: true,  // 默认播放
      // //视频播放包裹元素
      // id: "video-container",
      // //萤石token
      // accessToken: "at.9xw5lyrs565fuwq8d4tit1lodiz0me4x-7ljdr8v8ll-153eu4h-8fslpq3ks",
      // url: "ezopen://open.ys7.com/J93439444/1.hd.live", // 播放地址
      // template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // // header: ['capturePicture', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
      // plugin: ['talk'], // 加载插件，talk-对讲
      // // 视频下方底部控件
      // // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
      // footer: ['talk', 'hd', 'fullScreen'], // 如果template参数不为simple,该字段将被覆盖
      // audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
      // // openSoundCallBack: data => console.log("开启声音回调", data),
      // // closeSoundCallBack: data => console.log("关闭声音回调", data),
      // // startSaveCallBack: data => console.log("开始录像回调", data),
      // // stopSaveCallBack: data => console.log("录像回调", data),
      // // capturePictureCallBack: data => console.log("截图成功回调", data),
      // // fullScreenCallBack: data => console.log("全屏回调", data),
      // // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
      // //宽度以及高度
      //  });  




    });




    return {
      myChart,
      player,
      ezvizPlay
    };

  }
});
