
import { defineComponent, ref, watchEffect, Ref, onMounted } from 'vue';
import * as echarts from "echarts";
import { get7day } from '@/api/api'

export default defineComponent({
  name: 'RbsPassRate7Day',
  setup() {
    const myChart = ref<HTMLElement>();  //也可以用const myChart = ref<any>();

    const myCharts = ref<any>();
    const InfoData = ref([]);

    const names = [];
    const totals = [];
    const qualifieds = [];

    onMounted(() => {

      get7day({ type: '0' }).then((res: any) => {


        if (res.code == 0) {


          InfoData.value = res.result.rbs7dayAccuracy

          res.result.rbs7dayAccuracy.forEach((item) => {

            names.push(item.date)
            totals.push(item.total)
            qualifieds.push(item.qualified)

          })


          chartInit()


        }


      })

    });

    const chartInit = () => {
      myCharts.value = echarts.init(myChart.value!);

      myCharts.value.setOption({

        title: {
          text: ''
        },
        tooltip: {
          // trigger: 'axis',
          // axisPointer: {
          //   type: 'shadow'
          // }
        },
        legend: {

          textStyle: {
            fontSize: 14,
            color: '#fff'
          }

        },

        grid: {
          top: 50,
          left: 40,
          right: 5,
          bottom: 0,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: names
        },
        yAxis: {
          type: 'value',
          // data: ['Brazil', 'Indonesia', 'USA', 'India', 'China', 'World']
        },
        series: [
          {
            name: '投放次数',
            type: 'bar',
            data: totals
          },
          {
            name: '合格次数',
            type: 'bar',
            data: qualifieds
          }
        ]
      });
    }








    return {
      myChart,
    };

  }
});
