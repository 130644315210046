
import { defineComponent, ref, watchEffect,Ref } from 'vue';
import * as echarts from "echarts";

export default defineComponent({
  name: 'DeptChatData',
  setup() {
    const myChart = ref<HTMLElement>();  //也可以用const myChart = ref<any>();

    const datalist: number[] = [32, 70,73,52,41];
    const namelist: string[] = ['基础学院', '人文学院', '管理学院', '信息工程学院', '计策工程学院'];


    const myCharts = ref<any>();
    setTimeout(() => {
      // 绘制图表
      myCharts.value = echarts.init(myChart.value!);
      myCharts.value.setOption({
        grid: {
          top: '0',
          left: '1%',
          right: '10%',
          bottom: '0'
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        yAxis: [{
          data: ['基础学院', '人文学院', '管理学院', '信息工程学院', '计策工程学院'],
          inverse: true,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#fff',
            fontSize: 18
          }
        },
          {
            type: 'category',
            axisTick: { show: false }, // 不显示刻度线
            axisLabel: {
              color: '#fff',
            },
            axisLine: {
              show: false,
            },
            inverse: true,
          }
        ],
        series: [
          {
            name: '进度条',
            yAxisIndex: 0,
            type: 'pictorialBar',
            symbol: 'rect', // 长方形
            symbolRepeat: 'fixed',
            symbolMargin: '2', // 缝隙间隔宽
            symbolClip: true,
            symbolSize: ['3', '10'], //缝隙高度
            barCategoryGap: 5,
            label: {
              show: true,
              position: [0,2], //文字位置
              fontSize: 14,
              color:"#ffffff",
              formatter: (name: any) => namelist[name.dataIndex]
            },
            zlevel: 1, //  zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
            data: [
              {
                value: 32,
                itemStyle: {
                  normal: {
                    color: '#047CFF'
                  }
                }
              },
              {
                value: 70,
                itemStyle: {
                  normal: {
                    color: '#ffff02'
                  }
                }
              },
              {
                value: 73,
                itemStyle: {
                  normal: {
                    color: '#46c163'
                  }
                }
              },
              {
                value: 52,
                itemStyle: {
                  normal: {
                    color: '#ae2ec9'
                  }
                }
              },
              {
                value: 41,
                itemStyle: {
                  normal: {
                    color: '#c6390d'
                  }
                }
              }
            ]
          },
          {
            name: '进度条背景',
            type: 'bar',
            yAxisIndex: 1,
            barGap: '-20%', // 不同系列的柱间距离，为百分比。
            barCategoryGap: 5,
            barWidth: 10,
            left: '50',
            data: [100, 100, 100, 100, 100],
            color: 'rgba(152, 144, 238, 0.12)', // 柱条颜色
            itemStyle: {
              normal: {
                color: (params: any) => {
                  var colorList = ['rgba(4,124,255,0.4)','rgba(242,104,0,0.4)','rgb(255,224,117,0.4)','	rgb(79,163,255,0.4)','rgb(191,10,255,0.4)'];
                  return colorList[params.dataIndex]
                },
                barBorderRadius: [5]//设置圆角
              }
            },
            label: {
              normal: {
                show: true, // 是否显示进度条上方的百分比
                formatter: (series: any) => datalist[series.dataIndex]+'%',
                position: [300,-18],
                textStyle: {
                  color: '#fff', // 进度条上方百分比字体颜色
                  fontSize: 12
                }
              }
            }
          }
        ]
      });
    }, 10);

    return {
      myChart,
    };

  }
});
