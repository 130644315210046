<template>
    <div class="middle-lengend">
        <div class="lengend-content">
            <span class="item">
                <span class="green-lengend"></span>
                <a href="#" class="title_href">首页</a>
            </span>
            <span class="item">
                <span class="yellow-lengend"></span>
                <a href="#" class="title_href">学院官网</a>
            </span>
            <span class="item">
                <span class="purple-lengend"></span>
                <a href="#" class="title_href">管理</a>
            </span>
            <span class="item">
                <img src="/images/gaoliang.png">
                <a href="#" class="title_href">展示</a>
            </span>
        </div>
    </div>
</template>
<style lang="scss">
.middle-lengend {
    width: 100%;
    height: 80px;
    .lengend-content {
        height: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        .item {
            display: flex;
            align-items: center;
            padding: 0 15px;
            img {
                display: block;
                margin: 0;
            }
            a {
                margin-left: 15px;
            }
        }
    }

    .lengend-content span {
        line-height: 48px;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 16px;

    }

    .lengend-content span img {
        vertical-align: middle;
        margin-top: -4px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .red-lengend {
        height: 8px;
        width: 30px;
        background-color: #FF3774;
        border-radius: 5px;
    }

    .green-lengend {
        height: 8px;
        width: 30px;
        background-color: #6AE89C;
        border-radius: 5px;
    }

    .yellow-lengend {
        height: 8px;
        width: 30px;
        background-color: #a5b645;
        border-radius: 5px;
    }

    .purple-lengend {
        height: 8px;
        width: 30px;
        background-color: #a740be;
        border-radius: 5px;
    }

    .title_href {
        font-size: 14px;
        color: #ffffff;
        text-decoration: none;
    }
}
</style>