
import { defineComponent, ref, watchEffect, Ref, onMounted } from 'vue';
import { getDeptRanks } from '@/api/api'

export default defineComponent({
  name: 'MonthDeptRank',
  setup() {


    const InfoData = ref<any>(); // 定义响应式数据



    onMounted(() => {


      getDeptRanks({ type: '0' }).then((res: any) => {



        if (res.code == 0) {


          InfoData.value = res.result.monthDeptRanks

          console.log("1111")
          console.log(res.result.monthDeptRanks)
          console.log("1111")

          // InfoData.value = 

          // console.log(InfoData)



        }

      })


    });




    return {
      InfoData
    };

  }
});
