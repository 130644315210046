
import { defineComponent } from 'vue'

export default defineComponent({
    // 启用了类型推导
    props: {
        name: String,
        width: {
            type: String,
            default: 'auto'
        },
        height: {
            type: String,
            default: 'auto'
        },
        headerHeight: {
            type: String,
            default: '60px'
        }
    },
    data() {
        return {
            headerImage: '',
            headerImages: {
                todayRank: '/images/drtanjianpai.png',
                monthRank: '/images/bytjp.png',
                week7OtherRbsTrend: '/images/7qqtlj.png',
                week7RbsRightRate: '/images/7rtfhg.png',
                yearData: '/images/bnlj.png',
                userRank: '/images/tdrpm.png',
                departmentRank: '/images/yxpm.png',
                aiCamera: '/images/jiankong.png'
            }
        }
    },
    mounted() {
        this.headerImage = 'url('+this.headerImages[this.name]+')'
    }
})
