

import { defineComponent, ref, watchEffect, Ref, onMounted } from 'vue';

import { getEquips } from '@/api/api'

import {
	shallowRef,
} from 'vue';

declare let AMap: any;
declare let Loca: any;
export default {
	setup() {
		const listData = ref([]);
		const map = shallowRef(null);
		const loca = shallowRef(null)
		const altitudePl = 30
		const altitudeZMarker = 20
		const altitudeTriangle = 30
		const statusList = [{
			name: '全部',
			value: 0,
			icon: '',
			textColor: '#000',
		},
		{
			value: 1,
			name: '在线',
			icon: 'normal',
			textColor: '#00dd88',
		},
		{
			value: 2,
			name: '故障',
			icon: 'warn',
			textColor: '#ffc530',
		},
		{
			value: 3,
			name: '停维',
			icon: 'danger',
			textColor: '#03c9ff',
		},
		{
			value: 4,
			name: '离线',
			icon: 'danger',
			textColor: '#999',
		},
		{
			value: 5,
			name: '满桶',
			icon: 'danger',
			textColor: '#fe5837',
		},
		]
		return {
			map,
			loca,
			altitudePl,
			altitudeZMarker,
			altitudeTriangle,
			listData,
			statusList
		}
	},
	methods: {
		initMap() {

			this.map = new AMap.Map("mapcontainer", { //设置地图容器id
				zoom: 16.5,
				zooms: [16, 22],
				viewMode: '3D',
				pitch: 45,
				mapStyle: 'amap://styles/blue',
				center: [120.01571, 29.222396],
			});


			getEquips({ type: '0' }).then((res: any) => {
				if (res.code == 0) {

					let equips = res.result

					equips.forEach((item) => {

						let equipInfo = {
							type: "Feature",
							geometry: {
								type: "Point",
								coordinates: item.coordinates
							},
							properties: {
								name: item.name,
								status: item.status ? item.status : 4,
								statusText: item.statusText
							}
						}

						this.listData.push(equipInfo);

						//this.initLoca()

						//投放设备标注
						let devicesGeo = new Loca.GeoJSONSource({
							data: {
								"type": "FeatureCollection",
								features: this.listData
							}
						})


						let zMarker = new Loca.ZMarkerLayer({
							//loca: this.loca,
							zIndex: 125,
							depth: false,
						});
						zMarker.setSource(devicesGeo);
						zMarker.setStyle({
							content: (i, feat) => {
								let props = feat.properties;
								let leftColor = 'rgba(33,33,33,0.6)'
								let rightColor = this.statusList[props.status].textColor
								let borderColor = this.statusList[props.status].textColor
								return (
									'<div style="width: 490px; height: 228px; padding: 0 0;">' +
									'<p style="display: block; height:80px; line-height:80px;font-size:40px;background-image: linear-gradient(to right, ' +
									leftColor + ',' + leftColor + ',' + rightColor +
									',rgba(0,0,0,0.4)); border:4px solid ' +
									borderColor +
									'; color:#fff; border-radius: 15px; text-align:center; margin:0; padding:5px;">' +
									props['name'] +
									': ' +
									this.statusList[props.status].name +
									'</p></div>'

									//'</p><span style="width: 130px; height: 130px; margin: 0 auto; display: block; background: url(/images/status/prism_' + props.status + '.png);"></span></div>'
								);
							},
							unit: 'meter',
							rotation: 15,
							//alwaysFront: true,
							size: [490 / 2, 222 / 2],
							altitude: this.altitudeZMarker,
						});
						this.loca.add(zMarker)

						//绘制标注特效
						// 浮动三角
						let triangleZMarker = new Loca.ZMarkerLayer({
							//loca: this.loca,
							zIndex: 125,
							depth: false,
						});
						triangleZMarker.setSource(devicesGeo);
						triangleZMarker.setStyle({
							content: (i, feat) => {
								let status = feat.properties.status
								return '<div style="width: 120px; height: 120px; background: url(/images/status/triangle_' +
									status + '.png);"></div>'
							},
							unit: 'meter',
							rotation: 15,
							//alwaysFront: true,
							size: [40, 60],
							altitude: this.altitudeTriangle,
						});
						triangleZMarker.addAnimate({
							key: 'altitude',
							value: [0.5, 1],
							random: true,
							transform: 1000,
							delay: 2000,
							yoyo: true,
							repeat: 999999,
						});
						this.loca.add(triangleZMarker)

						// 呼吸点 正常 绿色
						for (let status of this.statusList) {
							if (status.value > 0) {
								let scatterNormal = new Loca.ScatterLayer({
									//loca: this.loca,
									zIndex: 125,
									opacity: 1,
									visible: true,
									zooms: [2, 26],
									depth: false,
								});

								scatterNormal.setSource(devicesGeo);
								scatterNormal.setStyle({
									unit: 'meter',
									size: (i, feat) => {
										return feat.properties.status == status.value ? [60, 60] : [0, 0];
									},
									texture: '/images/status/scan_' + status.value + '.png',
									altitude: this.altitudePl,
									duration: 2000,
									animate: true,
								});
								this.loca.add(scatterNormal)
							}
						}

					})

				}
			})





		},
		initLoca() {
			this.loca = new Loca.Container({
				map: this.map,
			});
			if (this.loca) {
				this.loca.ambLight = {
					intensity: 0.3,
					color: '#fff',
				}
				this.loca.dirLight = {
					intensity: 0.6,
					color: '#fff',
					target: [0, 0, 0],
					position: [0, -1, 1],
				}
				this.loca.pointLight = {
					color: 'rgb(100,100,100)',
					// position: [120.24289, 30.341335, 20000],
					position: [120.015063, 29.224281, 20000],
					intensity: 3,
					// 距离表示从光源到光照强度为 0 的位置，0 就是光不会消失。
					distance: 50000,
				}


				//边界线
				let sharpData =
					"120.011892,29.225759;120.011882,29.225754;120.011877,29.225744;120.011875,29.225734;120.011867,29.225282;120.011948,29.224369;120.011956,29.224358;120.011967,29.224346;120.012301,29.223988;120.012322,29.223975;120.01235,29.22397;120.012376,29.22397;120.012404,29.223978;120.013913,29.224867;120.013936,29.224875;120.013959,29.224873;120.013979,29.224866;120.013994,29.224854;120.014225,29.224536;120.014405,29.224162;120.014448,29.223887;120.014434,29.223626;120.014408,29.223109;120.014362,29.22285;120.014206,29.222339;120.013959,29.220348;120.013963,29.220327;120.013973,29.220306;120.013985,29.220292;120.014003,29.220279;120.01504,29.219805;120.015552,29.219588;120.01609,29.219419;120.016339,29.219351;120.016588,29.219307;120.017102,29.219246;120.017618,29.219251;120.018124,29.219265;120.018205,29.219277;120.018285,29.2193;120.018308,29.219316;120.018328,29.219332;120.018347,29.219353;120.018363,29.219373;120.018369,29.219397;120.018373,29.21942;120.018375,29.219444;120.018367,29.219472;120.018239,29.21972;120.017484,29.220909;120.017181,29.221407;120.016784,29.222064;120.016688,29.222319;120.016673,29.222404;120.01666,29.222484;120.016651,29.222564;120.016651,29.22264;120.016664,29.223419;120.016677,29.2249;120.016676,29.225962;120.016657,29.227054;120.016642,29.227086;120.016624,29.227118;120.016597,29.227144;120.016567,29.227161;120.016527,29.227171;120.016487,29.227179;120.016442,29.227178;120.016397,29.227176;120.014765,29.226726;120.013133,29.22626;120.013152,29.226157;120.013007,29.226145;120.011903,29.225762;120.011892,29.225759"
				let coordinates = sharpData.split(";").map(function (poi) {
					return poi.split(",").map(function (f) {
						return parseFloat(f)
					})
				})

				//行政区流光

				let lineGeo = new Loca.GeoJSONSource({
					data: {
						"type": "FeatureCollection",
						"features": [{
							"type": "Feature",
							"properties": {
								"name": "中国计量大学现代学院"
							},
							"geometry": {
								"type": "LineString",
								"coordinates": coordinates
							},
						}]
					}
				})

				let lineLayer = new Loca.PulseLineLayer({
					// loca,
					zIndex: 130,
					opacity: 1,
					visible: true,
					zooms: [2, 22],
				})
				lineLayer.setSource(lineGeo)
				lineLayer.setStyle({
					altitude: this.altitudePl,
					lineWidth: 2,
					// 脉冲头颜色
					headColor: '#00dd88',
					// 脉冲尾颜色
					trailColor: 'rgba(128, 128, 128, 0.5)',
					// 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
					interval: 0.25,
					// 脉冲线的速度，几秒钟跑完整段路
					duration: 5000,
				})
				this.loca.add(lineLayer)
				this.loca.animate.start()


				//3d行政区
				let geo = new Loca.GeoJSONSource({
					data: {
						"type": "FeatureCollection",
						"features": [{
							"type": "Feature",
							"properties": {
								"name": "中国计量大学现代学院"
							},
							"geometry": {
								"type": "Polygon",
								"coordinates": [coordinates]
							},
						}]
					}
				})
				let pl = new Loca.PolygonLayer({
					//loca: this.loca,
					zIndex: 120,
					cullface: 'none',
					shininess: 1,
					hasBottom: true,
					blockHide: true,
					hasSide: true,
					hasTop: true,
					depth: true,
					opacity: 0.8,
				});

				pl.setSource(geo);
				pl.setStyle({
					topColor: function (index, feature) {
						return '#004835'//'rgba(85, 170, 255, 1.0)';
					},
					sideTopColor: function (index, feature) {
						return '#004835'//'rgba(85, 170, 255,0.7)';
					},
					sideBottomColor: function (index, feature) {
						return '#004835'//'rgba(85, 170, 255,1)';
					},
					height: this.altitudePl,
					altitude: 0,
				});
				this.loca.add(pl);


				//旋转动画
				const rotationAnimates = () => {
					this.loca.viewControl.addAnimates([{
						rotation: {
							value: 90, // 动画终点的地图旋转角度
							control: [
								[0, 0],
								[1, 90]
							], // 控制器，x是0～1的起始区间，y是rotation值
							timing: [0.42, 0, 0.4, 1], // 动画时间控制点
							duration: 2000, // 过渡时间，毫秒（ms）
						}
					}, {
						rotation: {
							value: 180, // 动画终点的地图旋转角度
							control: [
								[0, 90],
								[1, 180]
							], // 控制器，x是0～1的起始区间，y是rotation值
							timing: [0.42, 0, 0.4, 1], // 动画时间控制点
							duration: 2000, // 过渡时间，毫秒（ms）
						}
					}, {
						rotation: {
							value: 270, // 动画终点的地图旋转角度
							control: [
								[0, 180],
								[1, 270]
							], // 控制器，x是0～1的起始区间，y是rotation值
							timing: [0.42, 0, 0.4, 1], // 动画时间控制点
							duration: 2000, // 过渡时间，毫秒（ms）
						}
					}, {
						rotation: {
							value: 360, // 动画终点的地图旋转角度
							control: [
								[0, 270],
								[1, 360]
							], // 控制器，x是0～1的起始区间，y是rotation值
							timing: [0.42, 0, 0.4, 1], // 动画时间控制点
							duration: 2000, // 过渡时间，毫秒（ms）
						}
					}], function () {
						rotationAnimates()
					})
				}
				// rotationAnimates()

				// // //轨迹动画
				// function animate() {
				//     loca.viewControl.addTrackAnimate({
				//         duration: 30000, // 完成时间，毫秒
				//         //cubic-bezier(.17,.67,.83,.67)
				//         timing: [[0, 0], [1, 1]], // 动画速度控制器，使用贝塞尔曲线控制 [详细请看](https://cubic-bezier.com)
				//         path: coordinates,
				//         rotationSpeed: 90, // 在拐弯处每秒旋转多少度
				//     }, function () {
				//         // 动画完成的回调函数
				//         setTimeout(animate, 2000);
				//         console.log('结束');
				//     });
				// }

				// //启动动画
				// animate()



				// 启动帧
				this.loca.animate.start();

				// 图例
				var legendata = {
					loca: this.loca,
					title: {
						label: '设备运行状态',
						fontColor: 'rgba(255,255,255,0.4)',
						fontSize: '16px',
					},
					style: {
						backgroundColor: 'rgba(255,255,255,0.1)',
						left: '20px',
						bottom: '20px',
						fontSize: '12px',
						borderRadius: '6px',
					},
					dataMap: [],
				}
				legendata.dataMap = this.statusList.filter((item) => {
					if (item.value != 0) {
						return true
					} else {
						return false
					}
				}).map(item => {
					return {
						label: item.name,
						color: item.textColor
					}
				})
			
				var lengend = new Loca.Legend(legendata);

			}


		}
	},
	mounted() {
		this.initMap();
		this.initLoca();
	}

}
