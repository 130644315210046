
import { defineComponent, ref, watchEffect, Ref, onMounted, reactive } from 'vue';
import * as echarts from "echarts";
import { getDateData } from '@/api/api'
import { getToDayData } from '@/api/api'
import { collapseItemProps } from 'element-plus';

export default defineComponent({
  name: 'ToDayData',
  setup() {
    const myChart = ref<HTMLElement>();  //也可以用const myChart = ref<any>();
    // const datalist: number[] = [780, 300];
    // const namelist: string[] = ['易腐垃圾', '可回收物'];

    // const datalist: number[] = [780, 600, 770, 770, 770];
    // const namelist: string[] = ['水(m³)', '电(Kwh)', '蒸汽(t)', '天然气(m³)', '压缩空气(m³)'];

    const datalist = reactive<number[]>([]);
    const namelist = reactive<string[]>([]);

    const listData = ref([]); // 定义响应式数据
    const chatData = ref([]);
    const myCharts = ref<any>();

    chatData.value = [
      {
        value: 0,
        itemStyle: {
          normal: {
            color: '#047CFF'
          }
        }
      },
      {
        value: 0,
        itemStyle: {
          normal: {
            color: '#EE2800'
          }
        }
      }
    ]



    onMounted(() => {

      getToDayData({ type: '0' }).then((res: any) => {

       


        if (res.code == 0) {

          // console.log(999999999999)
          // console.log(res.result)
          // console.log(999999999999)

         

          datalist.push(res.result.rbsWeightTotal)
          datalist.push(res.result.recyclWeightTotal)
          namelist.push("易腐垃圾")
          namelist.push("可回收物")

          chartInit()


        }


      })

    });

    const chartInit = () => {
      myCharts.value = echarts.init(myChart.value!);
      myCharts.value.setOption({
        grid: {
          top: '0',
          left: '1%',
          right: '10%',
          bottom: '0'
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        yAxis: [{
          data: ['易腐垃圾', '可回收物'],
          inverse: true,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#fff',
            fontSize: 18
          }
        },
        {
          type: 'category',
          axisTick: { show: false }, // 不显示刻度线
          axisLabel: {
            color: '#000',
          },
          axisLine: {
            show: false,
          },
          inverse: true,
        }
        ],
        series: [
          {
            name: '进度条',
            yAxisIndex: 0,
            type: 'pictorialBar',
            symbol: 'rect', // 长方形
            symbolRepeat: 'fixed',
            symbolMargin: '2', // 缝隙
            symbolClip: true,
            symbolSize: ['3', '10'], //缝隙高度
            barCategoryGap: 5,
            label: {
              show: true,
              position: [0, 2], //文字位置
              fontSize: 14,
              color: "#ffffff",
              formatter: (name: any) => namelist[name.dataIndex]
            },
            zlevel: 1, //  zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
            data: [
              {
                value: 580,
                itemStyle: {
                  normal: {
                    color: '#047CFF'
                  }
                }
              },
              {
                value: 300,
                itemStyle: {
                  normal: {
                    color: '#EE2800'
                  }
                }
              }
            ]
          },
          {
            name: '进度条背景',
            type: 'bar',
            yAxisIndex: 1,
            barGap: '-100%', // 不同系列的柱间距离，为百分比。
            barCategoryGap: 5,
            barWidth: 10,
            left: '50',
            data: [100, 100],
            color: 'rgba(152, 144, 238, 0.12)', // 柱条颜色
            itemStyle: {
              normal: {
                color: (params: any) => {
                  var colorList = ['rgba(4,124,255,0.4)', 'rgba(242,104,0,0.4)'];
                  return colorList[params.dataIndex]
                },
                barBorderRadius: [5]//设置圆角
              }
            },
            label: {
              normal: {
                show: true, // 是否显示进度条上方的百分比
                formatter: (series) => datalist[series.dataIndex] + 'KG',
                position: [120, -18],
                textStyle: {
                  color: '#fff', // 进度条上方百分比字体颜色
                  fontSize: 14
                }
              }
            }
          }
        ]
      });
    }





    // setTimeout(() => {
    //   // 绘制图表
    //   myCharts.value = echarts.init(myChart.value!);
    //   myCharts.value.setOption({
    //     grid: {
    //       top: '0',
    //       left: '1%',
    //       right: '10%',
    //       bottom: '0'
    //     },
    //     xAxis: {
    //       axisLine: {
    //         show: false
    //       },
    //       axisTick: {
    //         show: false
    //       },
    //       axisLabel: {
    //         show: false
    //       },
    //       splitLine: {
    //         show: false
    //       }
    //     },
    //     yAxis: [{
    //       data: ['易腐垃圾1', '可回收物1'],
    //       inverse: true,
    //       splitLine: {
    //         show: false
    //       },
    //       axisTick: {
    //         show: false
    //       },
    //       axisLine: {
    //         show: false
    //       },
    //       axisLabel: {
    //         color: '#fff',
    //         fontSize: 18
    //       }
    //     },
    //       {
    //         type: 'category',
    //         axisTick: { show: false }, // 不显示刻度线
    //         axisLabel: {
    //           color: '#000',
    //         },
    //         axisLine: {
    //           show: false,
    //         },
    //         inverse: true,
    //       }
    //     ],
    //     series: [
    //       {
    //         name: '进度条',
    //         yAxisIndex: 0,
    //         type: 'pictorialBar',
    //         symbol: 'rect', // 长方形
    //         symbolRepeat: 'fixed',
    //         symbolMargin: '2', // 缝隙
    //         symbolClip: true,
    //         symbolSize: ['3', '10'], //缝隙高度
    //         barCategoryGap: 5,
    //         label: {
    //           show: true,
    //           position: [0,2], //文字位置
    //           fontSize: 14,
    //           color: "#ffffff",
    //           formatter: (name: any) => namelist[name.dataIndex]
    //         },
    //         zlevel: 1, //  zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
    //         data: [
    //           {
    //             value: 580,
    //             itemStyle: {
    //               normal: {
    //                 color: '#047CFF'
    //               }
    //             }
    //           },
    //           {
    //             value: 300,
    //             itemStyle: {
    //               normal: {
    //                 color: '#EE2800'
    //               }
    //             }
    //           }
    //         ]
    //       },
    //       {
    //         name: '进度条背景',
    //         type: 'bar',
    //         yAxisIndex: 1,
    //         barGap: '-100%', // 不同系列的柱间距离，为百分比。
    //         barCategoryGap: 5,
    //         barWidth: 10,
    //         left: '50',
    //         data: [1000, 1000],
    //         color: 'rgba(152, 144, 238, 0.12)', // 柱条颜色
    //         itemStyle: {
    //           normal: {
    //             color: (params: any) => {
    //               var colorList = ['rgba(4,124,255,0.4)','rgba(242,104,0,0.4)'];
    //               return colorList[params.dataIndex]
    //             },
    //             barBorderRadius: [5]//设置圆角
    //           }
    //         },
    //         label: {
    //           normal: {
    //             show: true, // 是否显示进度条上方的百分比
    //             formatter: (series) => datalist[series.dataIndex]+'/1000',
    //             position: [120,-18],
    //             textStyle: {
    //               color: '#fff', // 进度条上方百分比字体颜色
    //               fontSize: 14
    //             }
    //           }
    //         }
    //       }
    //     ]
    //   });
    // }, 10);

    return {
      myChart,
      datalist,
      namelist
    };

  }
});
