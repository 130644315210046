
import { defineComponent, ref, watchEffect, Ref, onMounted } from 'vue';
import { getDateData } from '@/api/api'

export default defineComponent({
  name: 'YearData',
  setup() {
    const InfoData = ref<any>(); // 定义响应式数据
    onMounted(() => {
      getDateData({ type: '0' }).then((res: any) => {
        console.log(res)
        if (res.code == 0) {

          InfoData.value = res.result.yearSummaryVo

          InfoData.value.totalReduce = convertToTons(InfoData.value.totalReduce)
          InfoData.value.total = convertToTons(InfoData.value.total)
          
        
        }

      })
    });


    const convertToTons = (grams: number): number => {
      const tons = grams / 1000000; // 1 ton = 1,000,000 grams
      const roundedTons = Math.round(tons); // Round to the nearest integer
      return roundedTons;
    };

    const convertToKilograms = (grams: number): number => {
      const kilograms = grams / 1000; // 1 kilogram = 1000 grams
      const roundedKilograms = Math.round(kilograms); // Round to the nearest integer
      return roundedKilograms;
    };




    

    return {
      InfoData,
      convertToTons
    };

  }
});
