
import { defineComponent, ref, watchEffect, Ref, onMounted, computed } from 'vue';
import * as echarts from "echarts";
import { getDateData } from '@/api/api'
import { getUserWeightRanks } from '@/api/api'
import { collapseItemProps } from 'element-plus';
import { result } from 'lodash';

export default defineComponent({
    name: 'userRank',
    setup() {


        const showRecyclable = ref<boolean>(true); // 默认显示可回收内容
        const recyclList = ref<any>();
        const otherList = ref<any>();







        onMounted(() => {

            getUserWeightRanks({ type: '0' }).then((res: any) => {




                if (res.code == 0) {

                    let recyclRank = res.result.userRecycRanks
                    let otherRank = res.result.userOtherRanks

                    recyclRank.forEach((item) => {
                        item.total = convertToKilograms(item.total)
                        return item;
                    })

                    otherRank.forEach((item) => {

                        item.total = convertToKilograms(item.total)
                        return item;
                    })


                    recyclList.value = res.result.userRecycRanks
                    otherList.value = res.result.userOtherRanks

                }


            })

        });


        const convertToKilograms = (grams: number): number => {
            const kilograms = grams / 1000; // 1 kilogram = 1000 grams
            const roundedKilograms = Math.round(kilograms); // Round to the nearest integer
            return roundedKilograms;
        };




        return { showRecyclable, recyclList, otherList };

    }
});
